<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},

	props: {
		checkedModel: Boolean,
	},

	model: {
		prop: 'checkedModel',
		event: 'updateModel',
	},
	data() {
		return {
			checked: false,
		}
	},
	watch: {
		checked(value) {
			this.$emit('updateModel', value)
		},
	},
}
</script>

<template>
	<div class="pb-2 h100 upsell-product-card-shadow-wrapper">
		<v-card
			:hover="!outOfStock ? true : false"
			outlined
			@click="checked = !checked"
			:ripple="false"
			:class="$bem('upsell-product-card-shadow')"
			class="h100 d-flex flex-column base rounded-md overflow-hidden product-card"
		>
			<v-hover v-slot="{ hover }" v-if="!outOfStock">
				<div :class="$bem('__image-cont')" @mouseover="firstImageHover = true">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" />
					</hook>
				</div>
			</v-hover>

			<div :class="$bem('__image-cont', 'mx-4 no-stock')" v-else>
				<hook zone="image">
					<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
				</hook>

				<hook zone="offer">
					<LimitedTimeOffer :product="product" />
				</hook>
			</div>

			<div class="d-flex align-center px-3 py-2" :class="{ 'no-stock': outOfStock }">
				<hook zone="prevPrice" v-if="discountPct">
					<del class="grey--text">
						<PriceText
							:amount="prevPrice"
							:currency="currency"
							:currencyMargin="0"
							:class="$bem('__prev-price')"
							class="grey--text mr-2 font-0"
							priceClass="grey--text"
						/>
					</del>
				</hook>

				<hook zone="price">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="$bem('__price')"
						class="price font-4 font-weight-bold"
					/>
				</hook>

				<hook zone="discount" v-if="discountPct > 0">
					<span :class="$bem('__discount')" class="discount font-weight-medium pa-2">
						{{ discountPct }}% OFF
					</span>
				</hook>
			</div>
			<v-spacer />

			<hook zone="attrs&financingTags" v-if="financing">
				<div
					:class="[
						$bem('__financing', 'font-0 line-clamp-1 px-3 font-weight-bold primary'),
						{ 'no-stock': outOfStock },
					]"
				>
					{{ financing }}
				</div>
			</hook>

			<v-spacer v-if="financing" />

			<hook zone="name">
				<div
					class="line-clamp-2 font-2 font-weight-bold px-3 header"
					:class="[
						$bem('__name', { 'mt-2': financing }),
						{ 'no-stock': outOfStock },
						{ 'mb-2': !brand },
					]"
				>
					{{ product.name }}
				</div>
			</hook>

			<hook zone="brand" v-if="brand">
				<div :class="[$bem('__brand', 'font-0 line-clamp-1 px-3 mt-1'), { 'no-stock': outOfStock }]">
					{{ brand }}
				</div>
			</hook>

			<v-spacer />

			<hook zone="event-icon">
				<CollectionEventIcon
					:product="product"
					:class="[$bem('__event-icon'), { 'no-stock': outOfStock }]"
				/>
			</hook>

			<div class="d-flex justify-center" v-if="!outOfStock">
				<v-checkbox v-model="checked" @click.stop="" class="mt-0 pt-0" />
				<span class="font-0 mb-2 gray--text">{{ 'Agregar' | lang }}</span>
			</div>
		</v-card>
	</div>
</template>

<style lang="scss" scoped>
.upsell-product-card-shadow-wrapper {
	min-height: 370px;
	width: 250px;
	min-width: 250px;
}

.upsell-product-card-shadow {
	position: relative;
	cursor: pointer;
	text-align: left;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
		width: 30px;
	}

	&__name {
		line-height: 1.6rem !important;
	}

	&__prev-price {
		line-height: normal;
	}

	&__image-cont {
		border-bottom: 1px solid rgb(170, 170, 170);
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}
</style>

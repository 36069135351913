<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},

	props: {
		checkedModel: Boolean,
	},

	model: {
		prop: 'checkedModel',
		event: 'updateModel',
	},
	data() {
		return {
			checked: false,
		}
	},
	watch: {
		checked(value) {
			this.$emit('updateModel', value)
		},
	},
}
</script>

<template>
	<div class="pb-2 h100 upsell-produc-card-left-aligned-wrapper">
		<v-card
			:hover="!outOfStock ? true : false"
			outlined
			@click="checked = !checked"
			:ripple="false"
			:class="$bem('upsell-produc-card-left-aligned')"
			class="h100 d-flex flex-column base rounded-md overflow-hidden product-card"
		>
			<v-hover v-slot="{ hover }" class="mx-4" v-if="!outOfStock">
				<div :class="$bem('__image-cont')" @mouseover="firstImageHover = true">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer" v-if="hasLimitedTimeOffer">
						<LimitedTimeOffer :product="product" />
					</hook>

					<hook zone="event-icon" v-else>
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>
			</v-hover>

			<div :class="$bem('__image-cont', 'mx-4 no-stock')" v-else>
				<hook zone="image">
					<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
				</hook>

				<hook zone="offer" v-if="hasLimitedTimeOffer">
					<LimitedTimeOffer :product="product" />
				</hook>

				<hook zone="event-icon" v-else>
					<CollectionEventIcon v-if="hasEventIcon" :product="product" :class="$bem('__event-icon')" />
				</hook>
			</div>

			<hook zone="price">
				<PriceText
					:amount="price"
					:currency="currency"
					:class="[$bem('__price'), { 'no-stock': outOfStock }]"
					class="price font-6 mt-6 line-clamp-1 px-4"
				/>
			</hook>

			<div
				class="d-flex align-center pb-2 line-clamp-1 px-4"
				:class="[$bem('__prev-price'), { 'no-stock': outOfStock }]"
				v-if="prevPrice"
			>
				<hook zone="prevPrice">
					<del class="grey--text">
						<PriceText
							:amount="prevPrice"
							:currency="currency"
							:currencyMargin="0"
							priceClass="grey--text font-2"
						/>
					</del>
				</hook>

				<hook zone="discount">
					<span
						:class="$bem('__discount')"
						class="d-inline-block discount font-2"
						v-if="discountPct > 0"
					>
						{{ discountPct }}% OFF
					</span>
				</hook>
			</div>

			<hook zone="attrs&financingTags">
				<div
					:class="[
						$bem('__financing', 'font-0 line-clamp-1 px-4 pb-2 font-weight-bold primary text-start'),
						{ 'pt-2': !discountPct },
						{ 'no-stock': outOfStock },
					]"
				>
					{{ financing }}
				</div>
			</hook>

			<v-spacer :class="!discountPct && 'f-grow'" />

			<hook zone="name">
				<div
					:class="[$bem('__name'), { 'no-stock': outOfStock }]"
					class="line-clamp-2 font-1 px-4 header font-weight-regular text-start"
				>
					{{ product.name }}
				</div>
			</hook>

			<div class="d-flex justify-center" v-if="!outOfStock">
				<v-checkbox v-model="checked" @click.stop="" class="mt-0 pt-0" />
				<span class="font-0 mb-2 gray--text">{{ 'Agregar' | lang }}</span>
			</div>
		</v-card>
	</div>
</template>

<style lang="scss" scoped>
.upsell-produc-card-left-aligned-wrapper {
	min-height: 370px;
	width: 250px;
	min-width: 250px;
}

.upsell-produc-card-left-aligned {
	position: relative;
	cursor: pointer;
	border: 1px solid #eaeaea;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		width: 30px;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__name {
		margin-bottom: 15px;
	}
}

.no-stock {
	opacity: 0.4;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2 h100 upsell-product-card-shadow-wrapper"},[_c('v-card',{staticClass:"h100 d-flex flex-column base rounded-md overflow-hidden product-card",class:_vm.$bem('upsell-product-card-shadow'),attrs:{"hover":!_vm.outOfStock ? true : false,"outlined":"","ripple":false},on:{"click":function($event){_vm.checked = !_vm.checked}}},[(!_vm.outOfStock)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:_vm.$bem('__image-cont'),on:{"mouseover":function($event){_vm.firstImageHover = true}}},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.firstImageHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), hover ? _vm.$bem('__second-image--show') : ''],attrs:{"src":_vm.secondImage,"width":"100%"}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"offer"}},[_c('LimitedTimeOffer',{attrs:{"product":_vm.product}})],1)],1)]}}],null,false,230915798)}):_c('div',{class:_vm.$bem('__image-cont', 'mx-4 no-stock')},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}})],1),_c('hook',{attrs:{"zone":"offer"}},[_c('LimitedTimeOffer',{attrs:{"product":_vm.product}})],1)],1),_c('div',{staticClass:"d-flex align-center px-3 py-2",class:{ 'no-stock': _vm.outOfStock }},[(_vm.discountPct)?_c('hook',{attrs:{"zone":"prevPrice"}},[_c('del',{staticClass:"grey--text"},[_c('PriceText',{staticClass:"grey--text mr-2 font-0",class:_vm.$bem('__prev-price'),attrs:{"amount":_vm.prevPrice,"currency":_vm.currency,"currencyMargin":0,"priceClass":"grey--text"}})],1)]):_vm._e(),_c('hook',{attrs:{"zone":"price"}},[_c('PriceText',{staticClass:"price font-4 font-weight-bold",class:_vm.$bem('__price'),attrs:{"amount":_vm.price,"currency":_vm.currency}})],1),(_vm.discountPct > 0)?_c('hook',{attrs:{"zone":"discount"}},[_c('span',{staticClass:"discount font-weight-medium pa-2",class:_vm.$bem('__discount')},[_vm._v(" "+_vm._s(_vm.discountPct)+"% OFF ")])]):_vm._e()],1),_c('v-spacer'),(_vm.financing)?_c('hook',{attrs:{"zone":"attrs&financingTags"}},[_c('div',{class:[
					_vm.$bem('__financing', 'font-0 line-clamp-1 px-3 font-weight-bold primary'),
					{ 'no-stock': _vm.outOfStock } ]},[_vm._v(" "+_vm._s(_vm.financing)+" ")])]):_vm._e(),(_vm.financing)?_c('v-spacer'):_vm._e(),_c('hook',{attrs:{"zone":"name"}},[_c('div',{staticClass:"line-clamp-2 font-2 font-weight-bold px-3 header",class:[
					_vm.$bem('__name', { 'mt-2': _vm.financing }),
					{ 'no-stock': _vm.outOfStock },
					{ 'mb-2': !_vm.brand } ]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),(_vm.brand)?_c('hook',{attrs:{"zone":"brand"}},[_c('div',{class:[_vm.$bem('__brand', 'font-0 line-clamp-1 px-3 mt-1'), { 'no-stock': _vm.outOfStock }]},[_vm._v(" "+_vm._s(_vm.brand)+" ")])]):_vm._e(),_c('v-spacer'),_c('hook',{attrs:{"zone":"event-icon"}},[_c('CollectionEventIcon',{class:[_vm.$bem('__event-icon'), { 'no-stock': _vm.outOfStock }],attrs:{"product":_vm.product}})],1),(!_vm.outOfStock)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),_c('span',{staticClass:"font-0 mb-2 gray--text"},[_vm._v(_vm._s(_vm._f("lang")('Agregar')))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
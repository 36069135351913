<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'shop',
	metaInfo: {
		title: 'Upsells',
	},
	data() {
		return {
			upsells: null,
			loading: false,
		}
	},
	computed: {
		productId: get('route@params.id'),
		listId() {
			return 'upsells'
		},
		cardTypeName() {
			let type = this.type || this.$srv('productCardType.name', 'LeftAligned')
			let concept = type.replace('WithBtn', '')
			return concept
		},
	},
	methods: {
		async addSelectedProductsToCart() {
			this.loading = true
			for (let [index, product] of this.upsells.entries()) {
				if (product.selected) {
					this.$eventer().trigger('product:click', {
						product,
						listId: this.listId,
						index,
					})
					let variant = product.variants.find((variant) => variant.forDisplay)
					await this.$shop.addToCart({
						productId: product.id,
						variantId: variant.id,
						qty: 1,
						sum: false,
						showUpsells: false,
						loading: false,
						showCart: false,
					})
				}
			}

			await this.$shop.goShop()
			this.$shop.showCart()
		},
	},
	async mounted() {
		let upsells = this.$store.get('shop/upsells') || null
		this.$store.set('shop/upsells', null)
		if (!upsells?.length) {
			upsells = await this.$shop.getUpsells({ productId: this.productId })
		}
		if (!upsells?.length) {
			await this.$router.replace(this.$shop.getShopRoute())
			this.$shop.showCart()
			return
		}
		this.upsells = upsells
		for (let product of this.upsells) {
			this.$assign(product, { selected: false })
		}
		this.$meta().refresh()
		this.$trackers.pageView()
		this.$eventer().trigger('product-list:view', {
			products: this.upsells,
			listId: this.listId,
		})
	},
}
</script>
<template>
	<div class="d-flex align-center justify-center py-16" v-if="!upsells">
		<v-progress-circular indeterminate size="60" color="primary" />
	</div>
	<v-container class="d-flex flex-column align-center py-8" v-else>
		<div class="font-4 font-md-6 font-weight-bold text-center">
			{{ 'Sabemos que pueden gustarte estos productos… ¡No te los pierdas!' | lang }}
		</div>
		<div class="font-2 font-md-3 mt-3">
			{{ 'Seleccioná los productos que quieras agregar y completá tu pedido.' | lang }}
		</div>
		<div class="cards-container mt-5 mb-8">
			<div v-for="(product, i) of upsells" :key="i">
				<component
					:is="`UpsellProductCard-${cardTypeName}`"
					v-model="product.selected"
					:product="product"
					v-on="$listeners"
				/>
			</div>
		</div>
		<Button
			color="success"
			width="225px"
			height="50px"
			x-large
			@click="addSelectedProductsToCart()"
			:loading="loading"
		>
			{{ 'Continuar' | lang }}
		</Button>
	</v-container>
</template>
<style scoped>
.cards-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 250px));
	gap: 30px;
	justify-content: center;
}
</style>

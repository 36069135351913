import { render, staticRenderFns } from "./comp_UpsellProductCard-CenterAligned.vue?vue&type=template&id=54c11cb8&scoped=true&"
import script from "./comp_UpsellProductCard-CenterAligned.vue?vue&type=script&lang=js&"
export * from "./comp_UpsellProductCard-CenterAligned.vue?vue&type=script&lang=js&"
import style0 from "./comp_UpsellProductCard-CenterAligned.vue?vue&type=style&index=0&id=54c11cb8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c11cb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VHover } from 'vuetify/lib/components/VHover';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VHover,VSpacer})
